import React, { useCallback } from "react";
import { graphql } from "gatsby";

import { ContactForm, Layout, SectionTitle, SEO } from "components";
import { useTranslation } from "react-i18next";

const contact = () => {
  const { t } = useTranslation();

  const isAddressExist = useCallback(
    () => [t`contact.address`, t`contact.postcode`].find((e) => e.length > 0),
    []
  );

  return (
    <Layout>
      <SEO
        title={t`config.nav.contact`}
        description={t`informations.paragraph_1`}
      />
      <article className="contact container">
        <SectionTitle title={t`config.title.contact`} />
        <div className="contact__container">
          <section className="contact__form">
            <h2 className="contact__form-title">
              {t("config.contact.form_header", { email: t`contact.email` })}
            </h2>
            <ContactForm />
          </section>
          <section className="contact__content">
            <h1 className="contact__header">{t`config.header.name`}</h1>
            <div className="contact__details">
              <p>{t`contact.name`}</p>
              <p>{t`contact.university`}</p>
              <br />
              <p>{t`contact.tel`}</p>
              <p>{t`contact.email`}</p>
            </div>
            {isAddressExist() && (
              <div className="contact__address">
                <p>{t`config.contact.address`}</p>
                <ul>
                  <li>
                    <p>
                      {t`contact.address`}
                      <br />
                      {t`contact.postcode`}
                    </p>
                  </li>
                </ul>
              </div>
            )}
          </section>
        </div>
      </article>
    </Layout>
  );
};

export default contact;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
